.intro {
    background-image: url(/assets/images/hero/Climate_Custom_Background_Lrg_lib.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;

    @media #{$tablet-up} {
      height: calc(100vh - 130px);
    }

    &__copy {
        color: $white;
        position: absolute;
        width: 100%;
        margin: auto;
        left: 0;
        bottom: 16vh;
        z-index: 10;

        p {
          max-width: 600px;
          margin: auto;
        }

    }
}



/* ---------- Fog ---------- */

.fog {
    -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01, #foglayer_02, #foglayer_03 {
  height: 65%;
  top: 35%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01, #foglayer_01 .image02,
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation:  foglayer_moveme 8s linear infinite, foglayer_01_opacity 10s linear infinite;
  -moz-animation:  foglayer_moveme 8s linear infinite, foglayer_01_opacity 10s linear infinite;
  animation:  foglayer_moveme 8s linear infinite, foglayer_01_opacity 10s linear infinite;

  @media #{$tablet-up} {  
    -webkit-animation:  foglayer_moveme 10s linear infinite, foglayer_01_opacity 22s linear infinite;
    -moz-animation:  foglayer_moveme 10s linear infinite, foglayer_01_opacity 22s linear infinite;
    animation:  foglayer_moveme 10s linear infinite, foglayer_01_opacity 22s linear infinite;
  }
}
#foglayer_02, #foglayer_03 {
  -webkit-animation:  foglayer_moveme 10s linear infinite, foglayer_03_opacity 12s linear infinite;
  -moz-animation: foglayer_moveme 10s linear infinite, foglayer_03_opacity 12s linear infinite;
  animation:  foglayer_moveme 10s linear infinite, foglayer_03_opacity 12s linear infinite;

  @media #{$tablet-up} {
    -webkit-animation:  foglayer_moveme 18s linear infinite, foglayer_03_opacity 25s linear infinite;
    -moz-animation: foglayer_moveme 18s linear infinite, foglayer_03_opacity 25s linear infinite;
    animation:  foglayer_moveme 18s linear infinite, foglayer_03_opacity 25s linear infinite;
  }
}

/* ---------- Moving Fog ---------- */
#foglayer_01 .image01, #foglayer_01 .image02 {
  background: url(/assets/images/hero/Animated_Fog_Large.png) center center/ contain repeat transparent;
}
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02{
  background: url(/assets/images/hero/Animated_Fog_Small.png) center center/100% repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
@-moz-keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
@-o-keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
@keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
@-moz-keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
@-o-keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
@keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
@-moz-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
@-o-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
@keyframes foglayer_03_opacity {
  0% { opacity: .8; }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-moz-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-o-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}

@media only screen
  and (min-width: 280px)
  and (max-width: 767px) {
    #foglayer_01 .image01, #foglayer_01 .image02,
    #foglayer_02 .image01, #foglayer_02 .image02,
    #foglayer_03 .image01, #foglayer_03 .image02 {
      width: 100%;
    }
  }