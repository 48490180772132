

p,
a,
span,
ol li,
ul li,
strong {
	font-size: 16px;
	line-height: 1.5;
	font-family: $font-copy;

	@media #{$laptop-up} {
		font-size: 18px;
    }
}

p {
    margin-bottom: 1.5rem;
}

a {
	cursor: pointer;
}

dfn,
cite,
em,
i {
	font-style: italic;
}