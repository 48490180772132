h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    margin-top: 0;
    font-family: $font-headings;
}

h1, h2 {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 1.5rem;

    @media #{$tablet-up} {
        font-size: 35px;
    }

    @media #{$laptop-up} {
        font-size: 49px;
    }
}

h1 {
    text-transform: uppercase;
}

h3 {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 1.5rem;
}