.footer {
	@extend .site-header;
    background: $olive;
    border-top: 1px solid $white;
    padding: 1em 0;

   	nav {
   		flex-wrap: wrap;
   		justify-content: space-between;
   		display: block;

   		.logo-lockup {
   			flex-grow: 0;
   			width: auto;

   			.logo-lockup__first {
   				width: 200px;

   				@media #{$phablet-up} {
   					width: 145px;
   				}
	   		}
	   		.logo-lockup__second {
	   			text-align: left;
	   			font-size: .8em;

	   			@media #{$tablet-up} {
	   				font-size: .9em;
	   			}
	   		}
   		}
   		.trust img {
   			width: 250px;

   			@media #{$phablet-only} {
   				width: 225px;
   				
   			}
   			@media #{$desktop-up} {
   				width: 300px;
   			}
   		}
	    .menu-footer {
	    	li {
	    		display: list-item;
	    		list-style-type: none;

	    		a { 
		    		text-transform: uppercase;
		    		font-weight: 700;
		    		font-size: .8em;
		    	}
		    	@media #{$desktop-up} {
		    		display: inline-block;
		    		margin-right: 2rem;
		    	}
	    	}
	    	@media #{$tablet-up} {
	    		display: block;
	    	}
	    }
	    .twitter img {
	    	max-height: 30px;
	    	margin-top: .5em;

	    	@media #{$phablet-up} {
	    		margin-top: 0;
	    	}
	    	@media #{$tablet-up} {
	    		max-height: none;
	    	}
	    }
	    .flex-item {
    		margin-bottom: 1em;
    		text-align: center;

    		@media #{$phablet-up} {
    			width: 50%;
    			margin-bottom: .5em;
    			text-align: left;
    		}
    		@media #{$tablet-up} {
    			width: auto;
    		}
	    	&.trust, &.twitter {
	    		@media #{$phablet-up} {
	    			text-align: right;
	    		}
	    		@media (min-width: 750px) {
	    			text-align: left;
	    		}
	    	}
    	}
    	@media #{$phablet-up} {
   			display: flex;
   		}
	}
	@media #{$phablet-up} {
		padding: 2em 0;
	}
	@media #{$tablet-up} {
		padding: 3em 0;
	}
}