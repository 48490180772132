.site-header {
	background-color: $dark-navy;
	border-bottom: 1px solid $white;

	@media #{$tablet-up} {
		border-bottom: none;
	}
	
	nav {
		padding: 20px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media #{$tablet-up} {
			padding: 0;
		}
	}

	.menu {
		background-color: $dark-navy;
		display: none;
		margin: 0;
		position: absolute;
		top: 101px;
		left: 0;
		padding: 0;
		width: 100%;
		z-index: 1;

		@media #{$tablet-up} {
			display: block;
			position: relative;
			width: auto;
			top: 0;
		}

		&.open {
			display: block;

		}

		li {
			list-style-type: none;

			@media #{$up-to-tablet} {
				font-size: 14px;
				padding-left: 5%;
			}

			@media #{$tablet-up} {
				display: inline-block;
				margin-right: 2rem;
				vertical-align: middle;
			}

			a {
				@media #{$up-to-tablet} {
					display: block;
					padding: 1rem 0 0;
				}
			}
		}

		.nav-flag {
			width: 100px;
			height: 100px;
			margin-right: 0;
    		padding: 10px;
			background-color: $white;
			position: relative;

			@media #{$up-to-tablet} {
				float: right;
			    margin-top: -7.5rem;
			    margin-right: 5px;
			    height: 140px;
			}

			@media #{$tablet-up} {
				width: 130px;
				height: 130px;
    			padding: 20px 25px;
			}

			&:after {
				content: '';
				bottom: -58px;
			    left: 0;
			    position: absolute;
			    z-index: 1;
			    border-left: 50px solid transparent;
			    border-right: 50px solid transparent;
			    border-top: 58px solid #fff;

			    @media #{$tablet-up} {
					bottom: -71px;
				    border-left: 65px solid transparent;
				    border-right: 65px solid transparent;
				    border-top: 71px solid #fff;
			    }
			}

			img {
				margin-top: 10px;
			}
		}
	}

	.logo-lockup {
		flex-grow: 1;

		&__first {
			/*margin-right: 10px;*/
			margin-right: 1.5em;
			/*padding-right: px;*/
			/*padding-right: 1em;*/
			/*box-sizing: border-box;*/
			position: relative;
			width: 110px;

			&:after {
				background-color: $white;
				content: "";
				height: 35px;
				position: absolute;
				/*right: -4px;*/
				top: 2px;
				width: 1px;
				margin-left: 1.5em;
			}

			@media #{$tablet-up} {
				width: 145px;
			}

			@media #{$laptop-up} {
				width: 165px;
			}

			@media #{$desktop-up} {
				width: 200px;
			}
		}

		a {
			display: inline-block;
    		vertical-align: text-top;
    		font-weight: 600;
    		font-family: $font-copy;

    		font: {
				family: sans-serif;
				size: 9px;
			}

			@media #{$laptop-up} {
				font-size: 16px;
			}

			br:first-of-type {
				@media #{$tablet-up} {
					display: none;
				}
			}
			&.logo-lockup__second {
				margin-left: 1.5em;
				font-size: .9em;
				line-height: 1.4;
			}
		}
	}

	a {
		color: $white;
	}
}