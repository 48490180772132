/**
 *
 * Styles
 *
 * This is the root file for all styles, so every scss partial should be
 * imported here. Import statements should be grouped functionally.
 */
/**
 * Functions
 */
/**
 * Mixins
 */
a,
:link,
:visited {
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

p a,
p :link,
p :visited {
  text-decoration: underline; }
  p a:hover, p a.btn,
  p :link:hover,
  p :link.btn,
  p :visited:hover,
  p :visited.btn {
    text-decoration: none; }

img {
  max-width: 100%; }

.divider {
  width: 100%;
  margin: 2rem 0;
  height: 20px;
  background-image: url(/assets/icons/hash-marks.svg);
  background-size: cover; }
  @media (min-width: 56.25em) {
    .divider {
      margin: 4rem 0; } }
  @media (min-width: 75em) {
    .divider {
      margin: 6rem 0; } }
  @media (min-width: 90em) {
    .divider {
      margin: 8rem 0; } }
  .divider-basic {
    border-bottom: 1px solid #626e75;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-top: -1rem;
    margin-bottom: 1.5rem; }

*:focus {
  outline: none; }

.slider {
  display: flex;
  position: relative; }
  .slider:after {
    content: "";
    position: absolute;
    height: 1px;
    width: calc(100% - 24px);
    background-color: #fff;
    top: 10px;
    left: 0; }
    @media (min-width: 75em) {
      .slider:after {
        width: calc(90%); } }
  .slider-vulnerability:after {
    left: 24px; }
    @media (min-width: 75em) {
      .slider-vulnerability:after {
        left: 10%; } }
  .slider span {
    height: 20px;
    flex-grow: 1;
    position: relative;
    width: 30%; }
    .slider span.low:before, .slider span.med:before, .slider span.high:before {
      content: "";
      position: absolute;
      height: 28px;
      left: calc(50% + 2px);
      width: 1px;
      background-color: #fff;
      top: 0; }
    .slider span.neutral {
      border: 1px solid #fff;
      width: 25px;
      background-image: url(/assets/icons/hash-inactive.svg);
      background-size: 425px 50px;
      background-repeat: no-repeat;
      background-position: center; }
      @media (min-width: 75em) {
        .slider span.neutral {
          width: 10%; } }
      .slider span.neutral .text {
        width: 80px;
        text-indent: -55px; }
    .slider span .text {
      bottom: -2rem;
      color: #fff;
      font-size: 11px;
      left: 0;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      width: 100%; }
  .slider-vulnerability span.low {
    background-color: #E2CFA8; }
  .slider-vulnerability span.med {
    background-color: #C69739; }
  .slider-vulnerability span.high {
    background-color: #966800; }
  .slider-preparedness span.low {
    background-color: #07211D; }
  .slider-preparedness span.med {
    background-color: #375C57; }
  .slider-preparedness span.high {
    background-color: #6BB5A7; }
  .slider-wrapper {
    flex-basis: 40%; }
    @media (max-width: 56.25em) {
      .slider-wrapper {
        margin-bottom: 2.5rem; } }
  .sliders {
    position: relative; }
    @media (max-width: 56.25em) {
      .sliders {
        display: none; } }
    @media (min-width: 56.25em) {
      .sliders {
        display: flex;
        justify-content: space-between;
        align-items: center; } }

@media (max-width: 56.25em) {
  #reset {
    display: none; } }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  height: 21px !important;
  background: none !important;
  border: none !important; }

.ui-slider-handle {
  background-color: #fff !important;
  border-radius: 50% !important;
  height: 27px !important;
  width: 27px !important; }

* {
  margin: 0;
  padding: 0; }

/* Icon 1 */
#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 40px;
  height: 32px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  @media (min-width: 56.25em) {
    #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
      display: none; } }
  #nav-icon1:before, #nav-icon2:before, #nav-icon3:before, #nav-icon4:before {
    content: '';
    background-color: #fff;
    width: 1px;
    height: 80px;
    position: absolute;
    left: -1.5rem;
    top: -1.5rem; }

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

#nav-icon1 span:nth-child(1) {
  top: 0px; }

#nav-icon1 span:nth-child(2) {
  top: 12px; }

#nav-icon1 span:nth-child(3) {
  top: 24px; }

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px; }

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg); }

/* Icon 2 */
#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #d3531a;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0; }

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px; }

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px; }

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 18px; }

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 36px; }

#nav-icon2.open span:nth-child(1), #nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

#nav-icon2.open span:nth-child(2), #nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px; }

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px; }

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0; }

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0; }

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px; }

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px; }

/* Icon 3 */
#nav-icon3 span:nth-child(1) {
  top: 0px; }

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 18px; }

#nav-icon3 span:nth-child(4) {
  top: 36px; }

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%; }

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%; }

/* Icon 4 */
#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

#nav-icon4 span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

#nav-icon4 span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px; }

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0; }

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px; }

/** Set Base Font Sizes */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  margin-top: 0;
  font-family: acumin-pro, sans-serif; }

h1, h2 {
  font-size: 28px;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem; }
  @media (min-width: 56.25em) {
    h1, h2 {
      font-size: 35px; } }
  @media (min-width: 75em) {
    h1, h2 {
      font-size: 49px; } }

h1 {
  text-transform: uppercase; }

h3 {
  font-size: 28px;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem; }

p,
a,
span,
ol li,
ul li,
strong {
  font-size: 16px;
  line-height: 1.5;
  font-family: acumin-pro, sans-serif; }
  @media (min-width: 75em) {
    p,
    a,
    span,
    ol li,
    ul li,
    strong {
      font-size: 18px; } }

p {
  margin-bottom: 1.5rem; }

a {
  cursor: pointer; }

dfn,
cite,
em,
i {
  font-style: italic; }

.action-items {
  background-image: linear-gradient(#cab398, #cc9e6a, #cca36a);
  color: #fff;
  padding: 2rem 0; }
  @media (max-width: 56.25em) {
    .action-items .container {
      width: 100%; } }
  .action-items__table {
    border: 1px solid #fff;
    margin: 2rem 0; }
    @media (max-width: 56.25em) {
      .action-items__table {
        border: none;
        border-bottom: 1px solid #fff; } }
    @media (min-width: 56.25em) {
      .action-items__table {
        display: flex; } }
    .action-items__table-heading {
      border-right: 1px solid #fff;
      text-align: right;
      padding: 2rem; }
      @media (max-width: 56.25em) {
        .action-items__table-heading {
          text-align: center; } }
      @media (min-width: 56.25em) {
        .action-items__table-heading {
          flex-basis: 35%;
          flex-grow: 1;
          max-width: 281px;
          max-width: 281px;
          width: 35%; } }
      .action-items__table-heading span {
        font-size: 29px;
        font-weight: bold;
        text-transform: uppercase; }
    @media (min-width: 56.25em) {
      .action-items__table-tabs {
        flex-grow: 1;
        position: relative; } }
    @media (min-width: 56.25em) {
      .action-items__table-tabs.active .overlay {
        display: block; } }
    .action-items__table-tabs span {
      font-family: acumin-pro, sans-serif; }
    .action-items__table-tabs .overlay {
      display: none;
      position: absolute;
      background-color: #fff;
      color: #9B9B9B;
      height: 100%;
      width: calc(100% - 100px);
      left: 100px;
      padding: 1rem;
      overflow: scroll;
      z-index: 1; }
      @media (min-width: 75em) {
        .action-items__table-tabs .overlay {
          padding: 2rem; } }
    .action-items__table-tabs .tab {
      align-items: center;
      border: 1px solid #fff;
      cursor: pointer;
      transition: .25s all ease-in-out;
      min-height: 90px;
      padding: .75rem .25rem; }
      @media (max-width: 56.25em) {
        .action-items__table-tabs .tab {
          border-right: none;
          border-left: none;
          border-bottom: none; } }
      @media (min-width: 56.25em) {
        .action-items__table-tabs .tab {
          display: flex;
          min-height: none; } }
      .action-items__table-tabs .tab:last-of-type {
        border-bottom: none; }
      .action-items__table-tabs .tab:hover, .action-items__table-tabs .tab.open {
        background-color: #fff;
        color: #707070; }
        .action-items__table-tabs .tab:hover .number, .action-items__table-tabs .tab.open .number {
          color: #CCAF8F; }
      .action-items__table-tabs .tab.open .details {
        max-height: 999px; }
      .action-items__table-tabs .tab.open .heading {
        opacity: 0; }
    .action-items__table-tabs .heading {
      font-weight: bold;
      opacity: 1;
      transition: .2s all ease-in-out;
      vertical-align: middle; }
      @media (max-width: 56.25em) {
        .action-items__table-tabs .heading {
          line-height: 1.1;
          display: inline-block;
          width: calc(100% - 60px); } }
    .action-items__table-tabs .number {
      transition: .25s all ease-in-out;
      font-size: 30px;
      font-weight: bold;
      padding: 0 1rem; }
      @media (min-width: 56.25em) {
        .action-items__table-tabs .number {
          font-size: 45px;
          padding: 0 2.5rem;
          min-width: 105px; } }
    .action-items__table-tabs .details {
      max-height: 0px;
      overflow: hidden;
      padding: 0 5%;
      transition: .25s all ease-in-out; }
      @media (min-width: 56.25em) {
        .action-items__table-tabs .details {
          display: none; } }

.attribution {
  background-image: linear-gradient(#bfa479, #a7a284, #a7a284);
  color: #fff;
  padding: 0 0 2rem; }
  .attribution .container {
    padding-top: 2rem; }
    @media (min-width: 56.25em) {
      .attribution .container {
        padding-top: 3rem; } }
  .attribution .team {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 56.25em) {
      .attribution .team {
        margin-top: 4rem; } }
    @media (min-width: 75em) {
      .attribution .team {
        width: 80%; } }
    .attribution .team__wrapper {
      border-top: 1px solid #fff;
      padding: 2.5rem 0;
      margin-top: 1.5rem; }
    .attribution .team-member {
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      flex-basis: 33%;
      flex-grow: 1;
      padding: 1.5rem; }
      @media (max-width: 56.25em) {
        .attribution .team-member {
          flex-basis: 100%;
          border-right: none;
          border-bottom: 1px solid #fff !important;
          padding-left: 0 !important;
          padding-bottom: 1.5rem !important;
          padding-top: 1.5rem !important; } }
      .attribution .team-member .name {
        display: block;
        font-weight: bold;
        margin-bottom: 1rem; }
      .attribution .team-member:nth-of-type(3), .attribution .team-member:nth-of-type(6) {
        padding-right: 0;
        border-right: none; }
      .attribution .team-member:nth-of-type(1), .attribution .team-member:nth-of-type(2), .attribution .team-member:nth-of-type(3) {
        padding-top: 0; }
      .attribution .team-member:nth-of-type(1), .attribution .team-member:nth-of-type(4) {
        padding-left: 0; }
      .attribution .team-member:nth-of-type(4), .attribution .team-member:nth-of-type(5), .attribution .team-member:nth-of-type(6) {
        border-bottom: none;
        padding-bottom: 0; }
  @media (min-width: 75em) {
    .attribution__intro {
      width: 80%; } }
  .attribution__logos {
    margin: 2rem 0; }
    @media (min-width: 37.5em) {
      .attribution__logos {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
    @media (min-width: 75em) {
      .attribution__logos {
        width: 80%; } }
    .attribution__logos .logo {
      padding-right: 12%;
      height: auto;
      flex-basis: 33%;
      flex-grow: 1; }
      @media (max-width: 37.5em) {
        .attribution__logos .logo {
          margin: 2rem 0;
          max-width: 80%; } }
      .attribution__logos .logo img {
        max-width: 100%; }
      .attribution__logos .logo:first-of-type {
        max-width: 500px; }
      .attribution__logos .logo:last-of-type {
        flex-basis: 8%;
        max-width: 150px;
        padding-right: 0; }
      .attribution__logos .logo:nth-of-type(2) {
        max-width: 450px; }

.intro {
  background-image: url(/assets/images/hero/Climate_Custom_Background_Lrg_lib.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  text-align: center;
  position: relative;
  overflow: hidden; }
  @media (min-width: 56.25em) {
    .intro {
      height: calc(100vh - 130px); } }
  .intro__copy {
    color: #fff;
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    bottom: 16vh;
    z-index: 10; }
    .intro__copy p {
      max-width: 600px;
      margin: auto; }

/* ---------- Fog ---------- */
.fog {
  -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0))); }

.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2); }

#foglayer_01, #foglayer_02, #foglayer_03 {
  height: 65%;
  top: 35%;
  position: absolute;
  width: 200%; }

#foglayer_01 .image01, #foglayer_01 .image02,
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%; }

#foglayer_01 {
  -webkit-animation: foglayer_moveme 8s linear infinite, foglayer_01_opacity 10s linear infinite;
  -moz-animation: foglayer_moveme 8s linear infinite, foglayer_01_opacity 10s linear infinite;
  animation: foglayer_moveme 8s linear infinite, foglayer_01_opacity 10s linear infinite; }
  @media (min-width: 56.25em) {
    #foglayer_01 {
      -webkit-animation: foglayer_moveme 10s linear infinite, foglayer_01_opacity 22s linear infinite;
      -moz-animation: foglayer_moveme 10s linear infinite, foglayer_01_opacity 22s linear infinite;
      animation: foglayer_moveme 10s linear infinite, foglayer_01_opacity 22s linear infinite; } }

#foglayer_02, #foglayer_03 {
  -webkit-animation: foglayer_moveme 10s linear infinite, foglayer_03_opacity 12s linear infinite;
  -moz-animation: foglayer_moveme 10s linear infinite, foglayer_03_opacity 12s linear infinite;
  animation: foglayer_moveme 10s linear infinite, foglayer_03_opacity 12s linear infinite; }
  @media (min-width: 56.25em) {
    #foglayer_02, #foglayer_03 {
      -webkit-animation: foglayer_moveme 18s linear infinite, foglayer_03_opacity 25s linear infinite;
      -moz-animation: foglayer_moveme 18s linear infinite, foglayer_03_opacity 25s linear infinite;
      animation: foglayer_moveme 18s linear infinite, foglayer_03_opacity 25s linear infinite; } }

/* ---------- Moving Fog ---------- */
#foglayer_01 .image01, #foglayer_01 .image02 {
  background: url(/assets/images/hero/Animated_Fog_Large.png) center center/contain repeat transparent; }

#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02 {
  background: url(/assets/images/hero/Animated_Fog_Small.png) center center/100% repeat transparent; }

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: .1; }
  22% {
    opacity: .5; }
  40% {
    opacity: .28; }
  58% {
    opacity: .4; }
  80% {
    opacity: .16; }
  100% {
    opacity: .1; } }

@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: .1; }
  22% {
    opacity: .5; }
  40% {
    opacity: .28; }
  58% {
    opacity: .4; }
  80% {
    opacity: .16; }
  100% {
    opacity: .1; } }

@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: .1; }
  22% {
    opacity: .5; }
  40% {
    opacity: .28; }
  58% {
    opacity: .4; }
  80% {
    opacity: .16; }
  100% {
    opacity: .1; } }

@keyframes foglayer_01_opacity {
  0% {
    opacity: .1; }
  22% {
    opacity: .5; }
  40% {
    opacity: .28; }
  58% {
    opacity: .4; }
  80% {
    opacity: .16; }
  100% {
    opacity: .1; } }

/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: .5; }
  25% {
    opacity: .2; }
  50% {
    opacity: .1; }
  80% {
    opacity: .3; }
  100% {
    opacity: .5; } }

@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: .5; }
  25% {
    opacity: .2; }
  50% {
    opacity: .1; }
  80% {
    opacity: .3; }
  100% {
    opacity: .5; } }

@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: .5; }
  25% {
    opacity: .2; }
  50% {
    opacity: .1; }
  80% {
    opacity: .3; }
  100% {
    opacity: .5; } }

@keyframes foglayer_02_opacity {
  0% {
    opacity: .5; }
  25% {
    opacity: .2; }
  50% {
    opacity: .1; }
  80% {
    opacity: .3; }
  100% {
    opacity: .5; } }

/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: .8; }
  27% {
    opacity: .2; }
  52% {
    opacity: .6; }
  68% {
    opacity: .3; }
  100% {
    opacity: .8; } }

@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: .8; }
  27% {
    opacity: .2; }
  52% {
    opacity: .6; }
  68% {
    opacity: .3; }
  100% {
    opacity: .8; } }

@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: .8; }
  27% {
    opacity: .2; }
  52% {
    opacity: .6; }
  68% {
    opacity: .3; }
  100% {
    opacity: .8; } }

@keyframes foglayer_03_opacity {
  0% {
    opacity: .8; }
  27% {
    opacity: .2; }
  52% {
    opacity: .6; }
  68% {
    opacity: .3; }
  100% {
    opacity: .8; } }

/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0; }
  100% {
    left: -100%; } }

@-moz-keyframes foglayer_moveme {
  0% {
    left: 0; }
  100% {
    left: -100%; } }

@-o-keyframes foglayer_moveme {
  0% {
    left: 0; }
  100% {
    left: -100%; } }

@keyframes foglayer_moveme {
  0% {
    left: 0; }
  100% {
    left: -100%; } }

@media only screen and (min-width: 280px) and (max-width: 767px) {
  #foglayer_01 .image01, #foglayer_01 .image02,
  #foglayer_02 .image01, #foglayer_02 .image02,
  #foglayer_03 .image01, #foglayer_03 .image02 {
    width: 100%; } }

.interstitial {
  background-size: cover;
  padding: 7rem 0;
  text-align: center;
  color: #fff;
  position: relative; }
  @media (min-width: 56.25em) {
    .interstitial {
      padding: 15vw 0;
      min-height: 35vw; } }
  .interstitial h2 {
    max-width: 1100px;
    margin: auto;
    z-index: 1;
    position: relative; }
  .interstitial:before {
    content: '';
    position: absolute;
    background-color: #cca36a;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: .4; }
  .interstitial-1 {
    background-image: url(/assets/images/interstitial1.jpg); }
    .interstitial-1:before {
      background-color: #b1aeab; }
    .interstitial-1 h2 {
      max-width: 800px; }
  .interstitial-2 {
    background-image: url(/assets/images/interstitial2.jpg); }
    .interstitial-2:before {
      background-color: #cca36a; }
  .interstitial-3 {
    background-image: url(/assets/images/interstitial3.jpg); }
    .interstitial-3:before {
      background-color: #a7a284; }

.map {
  background-image: linear-gradient(#707e85, #b1aeab);
  color: #fff;
  text-align: center;
  padding: 0 0 2rem; }
  .map__intro-text {
    max-width: 960px;
    margin: auto; }
  .map-controls {
    padding-top: 2rem; }
    @media (min-width: 56.25em) {
      .map-controls {
        padding-top: 3rem; } }
  .map-controls__info-box {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin: 3rem 0 2rem; }
    @media (max-width: 56.25em) {
      .map-controls__info-box {
        margin: 3rem 0 1rem; } }
    .map-controls__info-box .container {
      display: flex;
      justify-content: space-between; }
    .map-controls__info-box .info-box {
      padding: 1.5rem 3% 1rem;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      width: 40%; }
      @media (max-width: 56.25em) {
        .map-controls__info-box .info-box {
          padding: 1rem 0 .75rem;
          width: 50%; } }
      .map-controls__info-box .info-box:nth-of-type(2) {
        border-right: 1px solid white;
        border-left: 1px solid white;
        width: 20%; }
        .map-controls__info-box .info-box:nth-of-type(2):after {
          background-image: url(icons/icon-how.svg);
          background-position: 1px 1px;
          background-repeat: no-repeat;
          border: 1px solid white;
          border-radius: 50%; }
        .map-controls__info-box .info-box:nth-of-type(2).open:after {
          background-image: url(icons/icon-how-dark.svg); }
        @media (max-width: 56.25em) {
          .map-controls__info-box .info-box:nth-of-type(2) {
            display: none; } }
      .map-controls__info-box .info-box:nth-of-type(3):after {
        background-image: url(icons/icon-preparedness.svg); }
      .map-controls__info-box .info-box:nth-of-type(3).open:after {
        background-image: url(icons/icon-preparedness-dark.svg); }
      @media (max-width: 56.25em) {
        .map-controls__info-box .info-box:nth-of-type(3) {
          border-left: 1px solid #fff; } }
      .map-controls__info-box .info-box.open .info-box__details {
        display: block; }
      .map-controls__info-box .info-box.open:after {
        background-image: url(icons/icon-vulnerability-dark.svg); }
      .map-controls__info-box .info-box.open:before {
        background-color: #fff; }
      .map-controls__info-box .info-box:before, .map-controls__info-box .info-box:after {
        content: "";
        background-image: url(icons/icon-vulnerability.svg);
        background-size: contain;
        height: 40px;
        width: 40px;
        position: absolute;
        top: -20px;
        left: calc( 50% - 20px);
        z-index: 100; }
        @media (max-width: 56.25em) {
          .map-controls__info-box .info-box:before, .map-controls__info-box .info-box:after {
            height: 32px;
            width: 32px; } }
      .map-controls__info-box .info-box:before {
        background-image: none;
        background: #7c878b;
        border-radius: 50%; }
      @media (max-width: 56.25em) {
        .map-controls__info-box .info-box:last-of-type .info-box__details {
          left: -100%; } }
      .map-controls__info-box .info-box__details {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        padding: 2rem 8%;
        background-color: #707e85;
        text-align: center;
        z-index: 10; }
        @media (max-width: 56.25em) {
          .map-controls__info-box .info-box__details {
            width: 200%; } }
        .map-controls__info-box .info-box__details p {
          font-size: 11px; }
      .map-controls__info-box .info-box .title {
        font-size: 14px;
        font-weight: bold;
        text-align: center; }
        .map-controls__info-box .info-box .title:after {
          content: "";
          background-image: url(/assets/icons/arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 12px;
          height: 12px;
          display: inline-block;
          position: relative;
          transform: rotate(90deg);
          margin-left: 3px;
          vertical-align: bottom; }
        @media (max-width: 56.25em) {
          .map-controls__info-box .info-box .title {
            font-size: 12px; } }
  .map__state-data {
    border: 1px solid #fff;
    max-width: 600px;
    text-align: left;
    margin: auto; }
    @media (max-width: 56.25em) {
      .map__state-data {
        border-right: none;
        border-left: none;
        max-width: none; } }
    .map__state-data > div {
      display: flex; }
    .map__state-data .headers,
    .map__state-data .stats {
      border-bottom: 1px solid #fff; }
      .map__state-data .headers span,
      .map__state-data .stats span {
        width: 30%;
        border-right: 1px solid #fff;
        padding: .5rem .75rem; }
        @media (max-width: 56.25em) {
          .map__state-data .headers span,
          .map__state-data .stats span {
            display: none; } }
        @media (min-width: 56.25em) {
          .map__state-data .headers span,
          .map__state-data .stats span {
            width: 25%; } }
        .map__state-data .headers span:nth-of-type(3),
        .map__state-data .stats span:nth-of-type(3) {
          width: 40%;
          border-right: none;
          font-size: 14px;
          line-height: 1.2;
          padding-bottom: 2rem; }
          @media (max-width: 56.25em) {
            .map__state-data .headers span:nth-of-type(3),
            .map__state-data .stats span:nth-of-type(3) {
              width: 100%;
              display: block;
              text-align: center; } }
          @media (min-width: 56.25em) {
            .map__state-data .headers span:nth-of-type(3),
            .map__state-data .stats span:nth-of-type(3) {
              width: 50%; } }
        .map__state-data .headers span.large,
        .map__state-data .stats span.large {
          font-size: 18px;
          font-weight: bold;
          padding-bottom: .5rem; }
          @media (max-width: 56.25em) {
            .map__state-data .headers span.large,
            .map__state-data .stats span.large {
              text-transform: uppercase; } }
          @media (min-width: 56.25em) {
            .map__state-data .headers span.large,
            .map__state-data .stats span.large {
              font-size: 23px; } }
        .map__state-data .headers span.score-headline,
        .map__state-data .stats span.score-headline {
          text-transform: uppercase;
          line-height: 1.1;
          font-size: 10px; }
          @media (min-width: 56.25em) {
            .map__state-data .headers span.score-headline,
            .map__state-data .stats span.score-headline {
              font-size: 13px; } }
        .map__state-data .headers span.score-num,
        .map__state-data .stats span.score-num {
          font-size: 48px;
          font-weight: bold;
          line-height: 45px; }
          @media (min-width: 56.25em) {
            .map__state-data .headers span.score-num,
            .map__state-data .stats span.score-num {
              font-size: 58px; } }
    @media (max-width: 56.25em) {
      .map__state-data .stats {
        border-bottom: none; } }
    .map__state-data .detials > div {
      display: block;
      width: 30%;
      border-right: 1px solid #fff;
      padding: .5rem .75rem; }
      @media (max-width: 56.25em) {
        .map__state-data .detials > div {
          display: none; } }
      @media (min-width: 56.25em) {
        .map__state-data .detials > div {
          width: 25%; } }
      .map__state-data .detials > div:nth-of-type(3) {
        width: 40%;
        border-right: none;
        background-color: #fff; }
        @media (max-width: 56.25em) {
          .map__state-data .detials > div:nth-of-type(3) {
            display: block;
            width: 100%;
            text-align: center; } }
        @media (min-width: 56.25em) {
          .map__state-data .detials > div:nth-of-type(3) {
            width: 50%; } }
        .map__state-data .detials > div:nth-of-type(3) a {
          color: #ACAAAA;
          font-size: 18px;
          font-weight: bold; }
          @media (min-width: 56.25em) {
            .map__state-data .detials > div:nth-of-type(3) a {
              font-size: 20px; } }
      .map__state-data .detials > div span {
        display: block;
        font-size: 11px; }
  .map-button__container {
    display: flex;
    border: 1px solid #fff;
    min-width: 355px; }
    @media (min-width: 75em) {
      .map-button__container {
        width: 30%;
        min-width: 415px; } }
    .map-button__container .map-button {
      border-right: 3px solid #fff;
      padding-bottom: 1.4rem;
      cursor: pointer;
      background-image: url(/assets/icons/hash-inactive.svg);
      background-size: 550px 23px;
      background-repeat: no-repeat;
      background-position: -11px 3rem; }
      @media (min-width: 75em) {
        .map-button__container .map-button {
          width: 34%; } }
      .map-button__container .map-button.reset span {
        line-height: 2.1; }
      .map-button__container .map-button.reset:hover, .map-button__container .map-button.reset.active {
        background-image: url(/assets/icons/hash-active.svg); }
      .map-button__container .map-button.vuln:hover, .map-button__container .map-button.vuln.active {
        background-image: url(/assets/icons/v-hash-active.svg); }
      .map-button__container .map-button.prep:hover, .map-button__container .map-button.prep.active {
        background-image: url(/assets/icons/p-hash-active.svg); }
      .map-button__container .map-button.last {
        border-right: none; }
      .map-button__container .map-button .text {
        font-size: 11px !important;
        display: inline-block;
        line-height: 1;
        padding: .75rem .2rem;
        border-bottom: 1px solid #fff;
        min-height: 49px;
        width: 100%;
        text-transform: uppercase; }

.svg-map {
  margin-top: 3rem;
  cursor: pointer;
  display: none; }
  @media (min-width: 56.25em) {
    .svg-map {
      display: block; } }
  .svg-map svg {
    width: 100%; }

.slider .slider-slide {
  position: relative;
  width: 200px; }

.slider a {
  position: absolute;
  cursor: pointer; }

.mobile-states {
  padding: 1rem 0 3rem; }
  @media (min-width: 56.25em) {
    .mobile-states {
      display: none; } }

@media (min-width: 56.25em) {
  .svg-wrapper {
    display: flex;
    align-items: flex-start; } }

@media (min-width: 56.25em) {
  .main-svg {
    flex-basis: 75%;
    margin: 0 5%; } }

.misc-states {
  border: 1px solid #fff;
  flex-basis: 13%;
  margin-top: 8rem; }
  .misc-states .misc-state {
    border-bottom: 1px solid #fff; }
    .misc-states .misc-state:last-of-type {
      border-bottom: none; }

.state-block__name {
  text-transform: uppercase;
  display: block;
  padding-bottom: 1.5rem;
  font-size: 11px; }

.disclaimer-box {
  padding: 3vw 1rem; }
  @media (max-width: 56.25em) {
    .disclaimer-box {
      display: none; } }
  .disclaimer-box .disclaimer-icon {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 1rem auto;
    display: block; }

#state-data {
  background-color: #a1a3a2; }
  #state-data > .container {
    padding: 4rem 0; }
    #state-data > .container .arrow-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 1rem;
      height: auto;
      border-right: 1px solid #626E75;
      align-self: stretch;
      border-left: 1px solid #626E75; }
      #state-data > .container .arrow-container .arrow {
        width: 12px;
        height: 15px;
        padding-top: 1px;
        transform: rotate(0deg);
        transition: .1s all ease-in-out; }
    #state-data > .container h1 {
      color: #fff;
      text-transform: none;
      line-height: 1;
      max-width: 600px;
      margin-bottom: 2rem; }
    #state-data > .container > div {
      font-family: acumin-pro, sans-serif;
      display: flex;
      background-color: #d8d8d8;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      color: #6A6868;
      flex-wrap: wrap; }
      #state-data > .container > div > div {
        text-align: center;
        height: 80px;
        display: flex;
        align-items: center;
        font-weight: 700;
        margin: 0;
        padding: 0; }
        #state-data > .container > div > div.state-data__state-name {
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #626E75;
          border-top: none;
          background: url(/assets/icons/med-low.png);
          background-position: -499px 0px;
          background-repeat: no-repeat;
          background-size: 600px; }
          @media (max-width: 56.25em) {
            #state-data > .container > div > div.state-data__state-name {
              background-position: -525px 0px; } }
          #state-data > .container > div > div.state-data__state-name.low-low {
            background-image: url(/assets/icons/low-low.png); }
          #state-data > .container > div > div.state-data__state-name.low-med {
            background-image: url(/assets/icons/low-med.png); }
          #state-data > .container > div > div.state-data__state-name.low-high {
            background-image: url(/assets/icons/low-high.png); }
          #state-data > .container > div > div.state-data__state-name.med-low {
            background-image: url(/assets/icons/med-low.png); }
          #state-data > .container > div > div.state-data__state-name.med-med {
            background-image: url(/assets/icons/med-med.png); }
          #state-data > .container > div > div.state-data__state-name.med-high {
            background-image: url(/assets/icons/med-high.png); }
          #state-data > .container > div > div.state-data__state-name.high-low {
            background-image: url(/assets/icons/high-low.png); }
          #state-data > .container > div > div.state-data__state-name.high-med {
            background-image: url(/assets/icons/high-med.png); }
          #state-data > .container > div > div.state-data__state-name.high-high {
            background-image: url(/assets/icons/high-high.png); }
          @media (min-width: 64.0625em) {
            #state-data > .container > div > div.state-data__state-name {
              width: 40%;
              border-bottom: none; } }
          #state-data > .container > div > div.state-data__state-name .header-holder {
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center; }
            @media (min-width: 56.25em) {
              #state-data > .container > div > div.state-data__state-name .header-holder {
                margin-left: 1rem; } }
            #state-data > .container > div > div.state-data__state-name .header-holder .state-name {
              font-size: 24px; }
              @media (min-width: 37.5em) {
                #state-data > .container > div > div.state-data__state-name .header-holder .state-name {
                  font-size: 30px; } }
            #state-data > .container > div > div.state-data__state-name .header-holder svg {
              height: 55px; }
          #state-data > .container > div > div.state-data__state-name img {
            max-height: 60px;
            margin-right: 2.5rem; }
            @media (max-width: 56.25em) {
              #state-data > .container > div > div.state-data__state-name img {
                margin-right: 1.75rem; } }
        #state-data > .container > div > div > span, #state-data > .container > div > div > a {
          margin: 0 auto;
          font-size: 18px; }
        #state-data > .container > div > div.state-data__dropdown {
          position: relative;
          display: flex; }
          #state-data > .container > div > div.state-data__dropdown.open .state-dropdown {
            display: block; }
          #state-data > .container > div > div.state-data__dropdown.open .arrow {
            transform: rotate(90deg); }
          @media (min-width: 64.0625em) {
            #state-data > .container > div > div.state-data__dropdown {
              border-left: 1px solid #626E75; } }
          @media (max-width: 37.5em) {
            #state-data > .container > div > div.state-data__dropdown span {
              padding: 0 1rem;
              line-height: 1.15; } }
        #state-data > .container > div > div.state-data__back {
          border: none; }
          #state-data > .container > div > div.state-data__back a {
            color: #6A6868;
            text-decoration: underline; }
            @media (max-width: 37.5em) {
              #state-data > .container > div > div.state-data__back a {
                padding: 0 1rem;
                line-height: 1.15; } }
        #state-data > .container > div > div.state-data__dropdown, #state-data > .container > div > div.state-data__back {
          width: 50%; }
          @media (min-width: 64.0625em) {
            #state-data > .container > div > div.state-data__dropdown, #state-data > .container > div > div.state-data__back {
              width: 30%; } }
  #state-data section {
    background-color: #d8d8d8; }
    #state-data section .header {
      color: #fff;
      min-height: 105px;
      font-family: acumin-pro, sans-serif;
      font-weight: 800;
      padding: 1rem; }
      @media (min-width: 37.5em) {
        #state-data section .header {
          padding: 1rem 2rem;
          min-height: 125px; } }
      #state-data section .header .state-data__score {
        font-size: 45px;
        line-height: 1.2;
        margin: 0 1rem 0 0;
        float: left; }
        @media (min-width: 37.5em) {
          #state-data section .header .state-data__score {
            font-size: 70px;
            margin: 0 2rem 0 0; } }
      #state-data section .header h3, #state-data section .header .state-data__rank {
        margin-top: 5px;
        font-weight: 800;
        font-size: 20px;
        text-transform: uppercase;
        display: inline-block; }
        @media (min-width: 37.5em) {
          #state-data section .header h3, #state-data section .header .state-data__rank {
            font-size: 24px; } }
        @media (min-width: 56.25em) {
          #state-data section .header h3, #state-data section .header .state-data__rank {
            font-size: 30px; } }
      #state-data section .header .state-data__scale {
        display: block;
        font-size: 14px;
        font-weight: 600; }
        #state-data section .header .state-data__scale span {
          font-size: 15px; }
          #state-data section .header .state-data__scale span:first-of-type {
            margin-right: 1rem; }
          @media (min-width: 37.5em) {
            #state-data section .header .state-data__scale span {
              display: inline-block; }
              #state-data section .header .state-data__scale span:first-of-type {
                margin-right: 2rem; } }
        @media (min-width: 37.5em) {
          #state-data section .header .state-data__scale {
            margin-top: .5rem; } }
    #state-data section .body {
      color: #626E75;
      position: relative; }
      #state-data section .body h4 {
        font-family: acumin-pro, sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px; }
        #state-data section .body h4.sideways {
          text-transform: uppercase;
          text-align: center;
          font-size: 20px;
          padding: 1rem 0;
          border-bottom: 1px solid #626E75; }
          @media (min-width: 56.25em) {
            #state-data section .body h4.sideways {
              transform-origin: 0 0;
              transform: rotate(-90deg) translateX(-250px);
              margin: 2rem 2.25rem;
              position: absolute;
              border-bottom: none;
              top: calc(50% - 170px);
              left: -18px; } }
        @media (min-width: 37.5em) {
          #state-data section .body h4 {
            font-size: 22px; } }
      #state-data section .body .body-groups .group {
        padding: 2.5rem 1.5rem; }
        #state-data section .body .body-groups .group:first-of-type {
          border-bottom: 1px solid #626E75; }
        #state-data section .body .body-groups .group p {
          font-size: 15px;
          margin: 1em 0;
          max-width: 750px; }
        #state-data section .body .body-groups .group .stats {
          display: flex;
          font-weight: 700;
          justify-content: flex-start;
          flex-wrap: wrap; }
          #state-data section .body .body-groups .group .stats .stat {
            display: flex;
            flex-direction: column;
            width: 120px;
            margin: 1em 0 0; }
            #state-data section .body .body-groups .group .stats .stat:last-of-type {
              /*margin-right: none;*/ }
            #state-data section .body .body-groups .group .stats .stat .name {
              text-transform: uppercase;
              font-size: 10px;
              max-width: 85px; }
            #state-data section .body .body-groups .group .stats .stat .number {
              font-size: 26px; }
            @media (min-width: 56.25em) {
              #state-data section .body .body-groups .group .stats .stat {
                margin-bottom: 0; } }
        #state-data section .body .body-groups .group.social .stats .name {
          height: 30px; }
        @media (min-width: 56.25em) {
          #state-data section .body .body-groups .group {
            /*padding: 1.5rem;*/
            border-left: 1px solid #626E75; } }
      @media (min-width: 56.25em) {
        #state-data section .body .body-groups {
          width: calc(100% - 100px);
          position: relative;
          left: 100px; } }
      #state-data section .body .definitions {
        display: flex;
        border-top: 1px solid #626E75;
        cursor: pointer; }
        #state-data section .body .definitions h4 {
          display: inline-block;
          height: 100%;
          padding: 1rem; }
        #state-data section .body .definitions.visible .arrow {
          transform: rotate(90deg); }
      #state-data section .body .expandable {
        justify-content: flex-start;
        flex-wrap: wrap;
        border-top: 1px solid #626E75;
        display: none; }
        #state-data section .body .expandable .expandable-item {
          width: 100%;
          padding: 1rem 1.5rem;
          border-left: 1px solid #626E75; }
          #state-data section .body .expandable .expandable-item h5 {
            text-transform: uppercase;
            font-weight: 700;
            border-bottom: 1px solid #a1a3a2;
            margin-bottom: 1.25rem;
            padding-bottom: 1.25rem;
            display: flex;
            align-items: baseline; }
          #state-data section .body .expandable .expandable-item p {
            font-size: 15px; }
          #state-data section .body .expandable .expandable-item:last-of-type {
            border-right: 1px solid #626E75; }
          #state-data section .body .expandable .expandable-item:first-of-type {
            border-left: none; }
          @media (min-width: 37.5em) {
            #state-data section .body .expandable .expandable-item {
              padding: 2rem 3rem; } }
        @media (max-width: 37.438em) {
          #state-data section .body .expandable .expandable-item {
            border-left: none;
            border-right: none; }
            #state-data section .body .expandable .expandable-item h5 {
              height: auto;
              padding-bottom: .5rem; } }
        @media (min-width: 37.5em) and (max-width: 56.25em) {
          #state-data section .body .expandable .expandable-item {
            width: 50%; }
            #state-data section .body .expandable .expandable-item:nth-of-type(2n+1) {
              border-left: none; }
            #state-data section .body .expandable .expandable-item:nth-of-type(2n) {
              border-right: none; } }
        @media (min-width: 56.25em) and (max-width: 75em) {
          #state-data section .body .expandable .expandable-item {
            width: 33.33%; }
            #state-data section .body .expandable .expandable-item:nth-of-type(3n+1) {
              border-left: none; }
            #state-data section .body .expandable .expandable-item:nth-of-type(3n) {
              border-right: none; } }
        @media (min-width: 75em) and (max-width: 90em) {
          #state-data section .body .expandable .expandable-item {
            width: 25%; }
            #state-data section .body .expandable .expandable-item:nth-of-type(4n+1) {
              border-left: none; }
            #state-data section .body .expandable .expandable-item:nth-of-type(4n) {
              border-right: none; } }
        @media (min-width: 90em) {
          #state-data section .body .expandable .expandable-item {
            width: 20%; }
            #state-data section .body .expandable .expandable-item:nth-of-type(5n+1) {
              border-left: none; }
            #state-data section .body .expandable .expandable-item:nth-of-type(5n) {
              border-right: none; } }
        #state-data section .body .expandable.visible {
          display: flex; }
    #state-data section#vulnerability .header.low {
      background-color: #E2CFA8; }
    #state-data section#vulnerability .header.med {
      background-color: #C69739; }
    #state-data section#vulnerability .header.high {
      background-color: #966800; }
    #state-data section#preparedness .header.low {
      background-color: #07211D; }
    #state-data section#preparedness .header.med {
      background-color: #375C57; }
    #state-data section#preparedness .header.high {
      background-color: #6BB5A7; }
    #state-data section#preparedness .body .body-groups .stats {
      padding-top: 1em; }
      #state-data section#preparedness .body .body-groups .stats:first-of-type {
        padding-bottom: 1.75em;
        padding-top: 0; }
      #state-data section#preparedness .body .body-groups .stats .stat {
        width: 50%; }
        #state-data section#preparedness .body .body-groups .stats .stat .name {
          max-width: 120px; }
        @media (min-width: 37.5em) {
          #state-data section#preparedness .body .body-groups .stats .stat {
            width: 150px; } }
    #state-data section#preparedness .body .body-groups .related .stats .stat {
      width: 50%; }
      #state-data section#preparedness .body .body-groups .related .stats .stat .name {
        max-width: 150px;
        min-height: auto;
        margin-bottom: 1rem; }
      @media (min-width: 37.5em) {
        #state-data section#preparedness .body .body-groups .related .stats .stat {
          width: 180px; } }

.state-data__preparedness--text,
.state-data__vulnerability--text {
  margin-left: 100px; }
  @media (min-width: 56.25em) {
    .state-data__preparedness--text,
    .state-data__vulnerability--text {
      margin-left: 136px; } }
  .state-data__preparedness--text h3,
  .state-data__vulnerability--text h3 {
    margin-bottom: 0; }

.state-dropdown {
  display: none;
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 1; }
  .state-dropdown ul {
    max-height: 300px;
    background-color: #d8d8d8;
    border: 1px solid #626E75;
    overflow: scroll;
    padding: 1rem 1.25rem; }
  .state-dropdown li {
    list-style-type: none;
    text-align: left; }
  .state-dropdown a {
    color: #626E75;
    font-size: 14px; }

.symbols .number,
.symbols .isTrue {
  background-size: contain;
  background-image: url(/assets/icons/x-off.svg);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: block; }

.symbols .isTrue {
  background-image: url(/assets/icons/check.svg); }

@media (max-width: 56.25em) {
  .state-data__container {
    width: 100% !important; } }

@media (min-width: 56.25em) {
  .state-data__container {
    margin-top: -7vw !important;
    margin-bottom: 3rem !important; } }

.mobile-details {
  display: flex; }
  @media (min-width: 56.25em) {
    .mobile-details {
      display: none !important; } }
  .mobile-details__rank {
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    flex-basis: 50%;
    padding: .25rem 0 .75rem;
    font-family: acumin-pro, sans-serif;
    line-height: 1; }
    .mobile-details__rank.v-low {
      background-color: #E2CFA8; }
    .mobile-details__rank.v-med {
      background-color: #C69739; }
    .mobile-details__rank.v-high {
      background-color: #966800; }
    .mobile-details__rank.p-low {
      background-color: #07211D; }
    .mobile-details__rank.p-med {
      background-color: #375C57; }
    .mobile-details__rank.p-high {
      background-color: #6BB5A7; }
    .mobile-details__rank:first-of-type {
      border-right: 1px solid #fff; }
    .mobile-details__rank span {
      font-weight: bold;
      font-size: 14px;
      display: block; }

.dropdown-container {
  border-bottom: 1px solid #fff;
  position: relative; }
  @media (min-width: 56.25em) {
    .dropdown-container {
      display: none; } }
  .dropdown-container .state-dropdown__homepage {
    display: block;
    position: relative;
    top: 0; }
  .dropdown-container .state-placeholder {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    display: block;
    padding-bottom: .75rem;
    cursor: pointer; }
    .dropdown-container .state-placeholder:after {
      content: "";
      background-image: url(/assets/icons/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 12px;
      height: 12px;
      display: inline-block;
      position: relative;
      transform: rotate(90deg);
      margin-left: 3px;
      vertical-align: bottom; }
  .dropdown-container.open ul {
    display: block; }
  .dropdown-container ul {
    background-color: #FFFFFF;
    position: absolute;
    width: 100%;
    top: 28px;
    padding: 0;
    max-height: 375px;
    display: none; }
    .dropdown-container ul li {
      color: #626E75;
      font-size: 14px;
      text-align: center;
      display: block;
      text-transform: uppercase;
      padding: 1rem 0 .75rem;
      border-bottom: 1px solid #626E75;
      font-weight: bold;
      cursor: pointer; }

.slick-arrow {
  position: absolute;
  left: 5%;
  top: calc(100% + 2rem); }
  .slick-arrow:before {
    content: "";
    background-image: url(/assets/icons/arrow-straight.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 50px;
    height: 30px;
    display: inline-block;
    position: relative; }
  .slick-arrow.slick-next {
    right: 5%;
    left: initial;
    transform: rotate(180deg); }

.state-page__btn-wrapper {
  opacity: 1;
  transition: .25s all ease-in-out; }
  .state-page__btn-wrapper.inactive {
    opacity: .5; }
    .state-page__btn-wrapper.inactive a {
      cursor: default; }
      .state-page__btn-wrapper.inactive a:hover {
        text-decoration: none;
        cursor: default; }

[data-vcolor="low"] {
  background-color: #E2CFA8; }

[data-vcolor="med"] {
  background-color: #C69739; }

[data-vcolor="high"] {
  background-color: #966800; }

[data-pcolor="low"] {
  background-color: #07211D; }

[data-pcolor="med"] {
  background-color: #375C57; }

[data-pcolor="high"] {
  background-color: #6BB5A7; }

@media (min-width: 56.25em) {
  .alaska .header-holder img {
    max-height: 120px !important;
    margin-left: -2.5rem;
    margin-right: 1.5rem; } }

.delaware .header-holder {
  margin-left: .5rem; }
  @media (min-width: 56.25em) {
    .delaware .header-holder {
      margin-left: 1.5rem !important; } }

@media (min-width: 56.25em) {
  .hawaii .header-holder img {
    max-height: 80px !important;
    margin-left: -.5rem; } }

section.download {
  background-color: #869283;
  padding: 5em 0; }
  section.download .container p {
    max-width: 900px;
    color: #fff;
    font-size: 1.2em; }
    @media (min-width: 56.25em) {
      section.download .container p {
        font-size: 1.4em; } }
  section.download .container .btn-container {
    display: block;
    margin-top: 2em; }
    section.download .container .btn-container a.btn {
      color: #fff;
      font-size: 1.3em;
      font-weight: 700;
      border: 1px solid #fff;
      padding: 1em;
      display: inline-block;
      width: 250px;
      text-align: center;
      /*margin-top: 2em;*/ }
      @media (min-width: 37.5em) {
        section.download .container .btn-container a.btn {
          width: auto; } }
      @media (min-width: 56.25em) {
        section.download .container .btn-container a.btn {
          font-size: 1.5em; } }
      @media (min-width: 75em) {
        section.download .container .btn-container a.btn {
          font-size: 1.6em;
          padding: 1.25em 1.5em; } }
    @media (min-width: 37.5em) {
      section.download .container .btn-container {
        display: inline-block; } }
    section.download .container .btn-container + .btn-container {
      margin: 1em 0 0;
      /*border: 2px solid green;*/ }
      @media (min-width: 37.5em) {
        section.download .container .btn-container + .btn-container {
          margin: 2em 0 0 1em; } }

.container {
  width: 90%;
  max-width: 1637px;
  margin: 0 auto; }

/* extra padding on homepage sections*/
body.home section > .container {
  max-width: 1400px; }

.grid-1, .grid-2, .grid-3, .grid-4, .grid-6 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .grid-1 > div, .grid-1 > li, .grid-2 > div, .grid-2 > li, .grid-3 > div, .grid-3 > li, .grid-4 > div, .grid-4 > li, .grid-6 > div, .grid-6 > li {
    width: 100%;
    margin-bottom: 5%; }
    @media (min-width: 56.25em) {
      .grid-1 > div, .grid-1 > li, .grid-2 > div, .grid-2 > li, .grid-3 > div, .grid-3 > li, .grid-4 > div, .grid-4 > li, .grid-6 > div, .grid-6 > li {
        width: 45.5%;
        margin-bottom: 0; } }
  @media (min-width: 56.25em) {
    .grid-1.last-row > div, .grid-1.last-row > li, .grid-2.last-row > div, .grid-2.last-row > li, .grid-3.last-row > div, .grid-3.last-row > li, .grid-4.last-row > div, .grid-4.last-row > li, .grid-6.last-row > div, .grid-6.last-row > li {
      margin-bottom: 0; } }

.grid-1 > div {
  width: 100%; }
  @media (min-width: 56.25em) {
    .grid-1 > div {
      width: 100%;
      margin-bottom: 4.5%; } }

@media (min-width: 56.25em) {
  .grid-2 > div, .grid-2 > article, .grid-2 > li, .grid-3 > div, .grid-3 > article, .grid-3 > li, .grid-4 > div, .grid-4 > article, .grid-4 > li, .grid-6 > div, .grid-6 > article, .grid-6 > li {
    margin-right: 4.5%; } }

@media (min-width: 56.25em) {
  .grid-2 > div:nth-child(even), .grid-2 > article:nth-child(even), .grid-4 > div:nth-child(even), .grid-4 > article:nth-child(even) {
    margin-right: 0; } }

@media (min-width: 37.5em) {
  .grid-6 > div, .grid-6 > article, .grid-6 > li {
    width: 49.2%;
    margin-bottom: 0; }
    .grid-6 > div:nth-child(even), .grid-6 > article:nth-child(even), .grid-6 > li:nth-child(even) {
      margin-right: 0; } }

@media (min-width: 56.25em) {
  .grid-6 > div:nth-child(even), .grid-6 > article:nth-child(even), .grid-6 > li:nth-child(even) {
    margin-right: 4.5%; } }

@media (min-width: 56.25em) {
  .grid-3 > div, .grid-3 > article, .grid-3 > li {
    width: calc(91%/3); }
    .grid-3 > div:nth-child(3n), .grid-3 > article:nth-child(3n), .grid-3 > li:nth-child(3n) {
      margin-right: 0; } }

@media (min-width: 37.5em) {
  .grid-6 > div, .grid-6 > article, .grid-6 > li {
    width: 49.2%;
    margin-bottom: 4.5%; }
    .grid-6 > div:nth-child(even), .grid-6 > article:nth-child(even), .grid-6 > li:nth-child(even) {
      margin-right: 0; }
    .grid-6 > div:nth-last-child(1), .grid-6 > div:nth-last-child(2), .grid-6 > article:nth-last-child(1), .grid-6 > article:nth-last-child(2), .grid-6 > li:nth-last-child(1), .grid-6 > li:nth-last-child(2) {
      margin-bottom: 0; } }

@media (min-width: 56.25em) {
  .grid-6 > div, .grid-6 > article, .grid-6 > li {
    width: calc(91%/3); }
    .grid-6 > div:nth-child(even), .grid-6 > article:nth-child(even), .grid-6 > li:nth-child(even) {
      margin-right: 4.5%; }
    .grid-6 > div:nth-child(3n), .grid-6 > article:nth-child(3n), .grid-6 > li:nth-child(3n) {
      margin-right: 0; }
    .grid-6 > div:nth-last-child(1), .grid-6 > div:nth-last-child(2), .grid-6 > div:nth-last-child(3), .grid-6 > article:nth-last-child(1), .grid-6 > article:nth-last-child(2), .grid-6 > article:nth-last-child(3), .grid-6 > li:nth-last-child(1), .grid-6 > li:nth-last-child(2), .grid-6 > li:nth-last-child(3) {
      margin-bottom: 0; } }

@media (min-width: 75em) {
  .grid-6 > div, .grid-6 > article, .grid-6 > li {
    margin-bottom: 0;
    width: calc(77.5%/6); }
    .grid-6 > div:nth-child(3n), .grid-6 > article:nth-child(3n), .grid-6 > li:nth-child(3n) {
      margin-right: 0; } }

@media (min-width: 75em) {
  .grid-4 > div, .grid-4 > article, .grid-4 > li {
    margin-bottom: 0;
    width: calc(86.5%/4); }
    .grid-4 > div:nth-child(even), .grid-4 > article:nth-child(even), .grid-4 > li:nth-child(even) {
      margin-right: 4.5%; }
    .grid-4 > div:nth-child(4n), .grid-4 > article:nth-child(4n), .grid-4 > li:nth-child(4n) {
      margin-right: 0; } }

.site-header, .footer {
  background-color: #3E5157;
  border-bottom: 1px solid #fff; }
  @media (min-width: 56.25em) {
    .site-header, .footer {
      border-bottom: none; } }
  .site-header nav, .footer nav {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 56.25em) {
      .site-header nav, .footer nav {
        padding: 0; } }
  .site-header .menu, .footer .menu {
    background-color: #3E5157;
    display: none;
    margin: 0;
    position: absolute;
    top: 101px;
    left: 0;
    padding: 0;
    width: 100%;
    z-index: 1; }
    @media (min-width: 56.25em) {
      .site-header .menu, .footer .menu {
        display: block;
        position: relative;
        width: auto;
        top: 0; } }
    .site-header .menu.open, .footer .menu.open {
      display: block; }
    .site-header .menu li, .footer .menu li {
      list-style-type: none; }
      @media (max-width: 56.25em) {
        .site-header .menu li, .footer .menu li {
          font-size: 14px;
          padding-left: 5%; } }
      @media (min-width: 56.25em) {
        .site-header .menu li, .footer .menu li {
          display: inline-block;
          margin-right: 2rem;
          vertical-align: middle; } }
      @media (max-width: 56.25em) {
        .site-header .menu li a, .footer .menu li a {
          display: block;
          padding: 1rem 0 0; } }
    .site-header .menu .nav-flag, .footer .menu .nav-flag {
      width: 100px;
      height: 100px;
      margin-right: 0;
      padding: 10px;
      background-color: #fff;
      position: relative; }
      @media (max-width: 56.25em) {
        .site-header .menu .nav-flag, .footer .menu .nav-flag {
          float: right;
          margin-top: -7.5rem;
          margin-right: 5px;
          height: 140px; } }
      @media (min-width: 56.25em) {
        .site-header .menu .nav-flag, .footer .menu .nav-flag {
          width: 130px;
          height: 130px;
          padding: 20px 25px; } }
      .site-header .menu .nav-flag:after, .footer .menu .nav-flag:after {
        content: '';
        bottom: -58px;
        left: 0;
        position: absolute;
        z-index: 1;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-top: 58px solid #fff; }
        @media (min-width: 56.25em) {
          .site-header .menu .nav-flag:after, .footer .menu .nav-flag:after {
            bottom: -71px;
            border-left: 65px solid transparent;
            border-right: 65px solid transparent;
            border-top: 71px solid #fff; } }
      .site-header .menu .nav-flag img, .footer .menu .nav-flag img {
        margin-top: 10px; }
  .site-header .logo-lockup, .footer .logo-lockup {
    flex-grow: 1; }
    .site-header .logo-lockup__first, .footer .logo-lockup__first {
      /*margin-right: 10px;*/
      margin-right: 1.5em;
      /*padding-right: px;*/
      /*padding-right: 1em;*/
      /*box-sizing: border-box;*/
      position: relative;
      width: 110px; }
      .site-header .logo-lockup__first:after, .footer .logo-lockup__first:after {
        background-color: #fff;
        content: "";
        height: 35px;
        position: absolute;
        /*right: -4px;*/
        top: 2px;
        width: 1px;
        margin-left: 1.5em; }
      @media (min-width: 56.25em) {
        .site-header .logo-lockup__first, .footer .logo-lockup__first {
          width: 145px; } }
      @media (min-width: 75em) {
        .site-header .logo-lockup__first, .footer .logo-lockup__first {
          width: 165px; } }
      @media (min-width: 90em) {
        .site-header .logo-lockup__first, .footer .logo-lockup__first {
          width: 200px; } }
    .site-header .logo-lockup a, .footer .logo-lockup a {
      display: inline-block;
      vertical-align: text-top;
      font-weight: 600;
      font-family: acumin-pro, sans-serif;
      font-family: sans-serif;
      font-size: 9px; }
      @media (min-width: 75em) {
        .site-header .logo-lockup a, .footer .logo-lockup a {
          font-size: 16px; } }
      @media (min-width: 56.25em) {
        .site-header .logo-lockup a br:first-of-type, .footer .logo-lockup a br:first-of-type {
          display: none; } }
      .site-header .logo-lockup a.logo-lockup__second, .footer .logo-lockup a.logo-lockup__second {
        margin-left: 1.5em;
        font-size: .9em;
        line-height: 1.4; }
  .site-header a, .footer a {
    color: #fff; }

.footer {
  background: #869283;
  border-top: 1px solid #fff;
  padding: 1em 0; }
  .footer nav {
    flex-wrap: wrap;
    justify-content: space-between;
    display: block; }
    .footer nav .logo-lockup {
      flex-grow: 0;
      width: auto; }
      .footer nav .logo-lockup .logo-lockup__first {
        width: 200px; }
        @media (min-width: 37.5em) {
          .footer nav .logo-lockup .logo-lockup__first {
            width: 145px; } }
      .footer nav .logo-lockup .logo-lockup__second {
        text-align: left;
        font-size: .8em; }
        @media (min-width: 56.25em) {
          .footer nav .logo-lockup .logo-lockup__second {
            font-size: .9em; } }
    .footer nav .trust img {
      width: 250px; }
      @media (min-width: 37.5em) and (max-width: 56.25em) {
        .footer nav .trust img {
          width: 225px; } }
      @media (min-width: 90em) {
        .footer nav .trust img {
          width: 300px; } }
    .footer nav .menu-footer li {
      display: list-item;
      list-style-type: none; }
      .footer nav .menu-footer li a {
        text-transform: uppercase;
        font-weight: 700;
        font-size: .8em; }
      @media (min-width: 90em) {
        .footer nav .menu-footer li {
          display: inline-block;
          margin-right: 2rem; } }
    @media (min-width: 56.25em) {
      .footer nav .menu-footer {
        display: block; } }
    .footer nav .twitter img {
      max-height: 30px;
      margin-top: .5em; }
      @media (min-width: 37.5em) {
        .footer nav .twitter img {
          margin-top: 0; } }
      @media (min-width: 56.25em) {
        .footer nav .twitter img {
          max-height: none; } }
    .footer nav .flex-item {
      margin-bottom: 1em;
      text-align: center; }
      @media (min-width: 37.5em) {
        .footer nav .flex-item {
          width: 50%;
          margin-bottom: .5em;
          text-align: left; } }
      @media (min-width: 56.25em) {
        .footer nav .flex-item {
          width: auto; } }
      @media (min-width: 37.5em) {
        .footer nav .flex-item.trust, .footer nav .flex-item.twitter {
          text-align: right; } }
      @media (min-width: 750px) {
        .footer nav .flex-item.trust, .footer nav .flex-item.twitter {
          text-align: left; } }
    @media (min-width: 37.5em) {
      .footer nav {
        display: flex; } }
  @media (min-width: 37.5em) {
    .footer {
      padding: 2em 0; } }
  @media (min-width: 56.25em) {
    .footer {
      padding: 3em 0; } }

html,
body {
  overflow-x: hidden; }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0; }
