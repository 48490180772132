.grid-1, .grid-2, .grid-3, .grid-4, .grid-6 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > div, & > li {
		width: 100%;
		margin-bottom: 5%;
		@media #{$tablet-up} {
			width: 45.5%;
			margin-bottom: 0;
		}
	}
	&.last-row {
		& > div, & > li {
			@media #{$tablet-up} {
				margin-bottom: 0;
			}
		}
	}
}

.grid-1 {
	& > div {
		width: 100%;
		@media #{$tablet-up} {
			width: 100%;
			margin-bottom: 4.5%;
		}
	}
}

.grid-2, .grid-3, .grid-4, .grid-6 {
	& > div, & > article, & > li {
		@media #{$tablet-up} {
			margin-right: 4.5%;
		}
	}
}

.grid-2, .grid-4 {
	& > div, & > article {
		@media #{$tablet-up} {
			&:nth-child(even) {
				margin-right: 0;
			}
		}
	}
}

.grid-6 {
	& > div, & > article, & > li {
		@media #{$phablet-up} {
			width: 49.2%;
			margin-bottom: 0;
	
			&:nth-child(even) {
				margin-right: 0;
			}
		}
		@media #{$tablet-up} {
			&:nth-child(even) {
				margin-right: 4.5%;
			}
		}
	}
}

.grid-3 {
	& > div, & > article, & > li {
		
		@media #{$tablet-up} {
			width: calc(91%/3);
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
}

.grid-6 {
	& > div, & > article, & > li {
		@media #{$phablet-up} {
			width: 49.2%;
			margin-bottom: 4.5%;
	
			&:nth-child(even) {
				margin-right: 0;
			}

			&:nth-last-child(1), &:nth-last-child(2) {
				margin-bottom: 0;
			}
		}

		@media #{$tablet-up} {
			width: calc(91%/3);
			&:nth-child(even) {
				margin-right: 4.5%;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}

			&:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) {
				margin-bottom: 0;
			}
		}

		@media #{$laptop-up} {
			margin-bottom: 0;
			width: calc(77.5%/6);

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
}

.grid-4 {
	& > div, & > article, & > li {
		@media #{$laptop-up} {
			margin-bottom: 0;
			width: calc(86.5%/4);

			&:nth-child(even) {
				margin-right: 4.5%;
			}
	
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}
}