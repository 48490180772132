.interstitial {
	background-size: cover;
	padding: 7rem 0;
	text-align: center;
	color: $white;
	position: relative;

	@media #{$tablet-up} {
	    padding: 15vw 0;
	    min-height: 35vw;
	}

	h2 {
		max-width: 1100px;
    	margin: auto;
    	z-index: 1;
    	position: relative;
	}

	&:before {
		content: '';
		position: absolute;
		background-color: #cca36a;
		z-index: 0;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		opacity: .4;
	}

	&-1 {
		background-image: url(/assets/images/interstitial1.jpg);

		&:before { background-color: #b1aeab; }

		h2 {
			max-width: 800px;
		}
	}
	&-2 {
		background-image: url(/assets/images/interstitial2.jpg);

		&:before { background-color: #cca36a; }
	}
	&-3 {
		background-image: url(/assets/images/interstitial3.jpg);

		&:before { background-color: #a7a284; }
	}
}