.container {
	width: 90%;
	max-width: 1637px;
	margin: 0 auto;
}

/* extra padding on homepage sections*/
body.home section > .container {
	max-width: 1400px;
}
