.action-items {
	background-image: linear-gradient(#cab398, #cc9e6a, #cca36a);
	color: $white;
	padding: 2rem 0;

	.container {
		@media #{$up-to-tablet} {
			width: 100%;
		}
	}

	&__table {
		border: 1px solid $white;
		margin: 2rem 0;

		@media #{$up-to-tablet} {
			border: none;
			border-bottom: 1px solid $white;
		}

		@media #{$tablet-up} {
			display: flex;
		}

		&-heading {
			border-right: 1px solid $white;
			text-align: right;
			padding: 2rem;

			@media #{$up-to-tablet} {
				text-align: center;
			}

			@media #{$tablet-up} {
				flex-basis: 35%;
				flex-grow: 1;
				max-width: 281px;
				max-width: 281px;
    			width: 35%;
			}

			span {
				font-size: 29px;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		&-tabs {
			@media #{$tablet-up} {
				flex-grow: 1;
				position: relative;
			}

			&.active {
				.overlay {
					@media #{$tablet-up} {
						display: block;
					}
				}
			}

			span {
				font-family: $font-headings;
			}

			.overlay {
				display: none;
				position: absolute;
				background-color: $white;
				color: $copy-grey;
				height: 100%;
				width: calc(100% - 100px);
				left: 100px;
				padding: 1rem;
				overflow: scroll;
				z-index: 1;

				@media #{$laptop-up} {
					padding: 2rem;
				}
			}

			.tab {
			    align-items: center;
			    border: 1px solid $white;
				cursor: pointer;
				transition: .25s all ease-in-out;
				min-height: 90px;
    			padding: .75rem .25rem;

    			@media #{$up-to-tablet} {
    				border-right: none;
    				border-left: none;
    				border-bottom: none;
    			}

				@media #{$tablet-up} {
					display: flex;
					min-height: none;
				}

				&:last-of-type {
					border-bottom: none;
				}

				&:hover,
				&.open  {
					background-color: $white;
					color: $table-text;

					.number {
						color: #CCAF8F;
					}
				}

				&.open {
					.details {
						max-height: 999px;
					}

					.heading {
						opacity: 0;
					}
				}
			}

			.heading {
				font-weight: bold;
				opacity: 1;	
				transition: .2s all ease-in-out;
   	 			vertical-align: middle;

				@media #{$up-to-tablet} {
   	 				line-height: 1.1;
					display: inline-block;
    				width: calc(100% - 60px);
    			}

			}

			.number {	
				transition: .25s all ease-in-out;
				font-size: 30px;
				font-weight: bold;
				padding: 0 1rem;

				@media #{$tablet-up} {
					font-size: 45px;
					padding: 0 2.5rem;
				    min-width: 105px;
				}
			}

			.details {
				max-height: 0px;
				overflow: hidden;
				padding: 0 5%;
				transition: .25s all ease-in-out;

				@media #{$tablet-up} {
					display: none;
				}
			}
		}
	}
}