section.download {
	background-color: $olive;
	padding: 5em 0;

	.container {
		p {
			max-width: 900px;
			color: $white;
			font-size: 1.2em;

			@media #{$tablet-up} {
				font-size: 1.4em;
			}
		}
		.btn-container {
			display: block;
			margin-top: 2em;

			a.btn {
				color: $white;
				font-size: 1.3em;
				font-weight: 700;
				border: 1px solid $white;
				padding: 1em;
				display: inline-block;
				width: 250px;
				text-align: center;
				
				/*margin-top: 2em;*/

				@media #{$phablet-up} {
					width: auto;
				}

				@media #{$tablet-up} {
					font-size: 1.5em;
				}

				@media #{$laptop-up} {
					font-size: 1.6em;
					padding: 1.25em 1.5em;
				}
			}
			@media #{$phablet-up} {
				display: inline-block;
			}

			& + .btn-container {
					margin: 1em 0 0;
					/*border: 2px solid green;*/

					@media #{$phablet-up} {
						margin: 2em 0 0 1em;
					}
				}
		}
	}
}