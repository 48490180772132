#state-data {
	background-color: $gray;

	& > .container {
		padding: 4rem 0;

		.arrow-container {
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: 0 1rem;
			height: auto;
			border-right: 1px solid $med-gray;
			align-self: stretch;
			border-left: 1px solid $med-gray;

			.arrow {
				width: 12px;
				height: 15px;
				padding-top: 1px;
				transform: rotate(0deg);
				transition: .1s all ease-in-out;	
			}
		}

		h1 {
			color: $white;
			text-transform: none;
			line-height: 1;
			max-width: 600px;
			margin-bottom: 2rem;
		}

		& > div {
			font-family: $font-headings;
			display: flex;
			background-color: $lightest-gray;
			justify-content: space-between;
			text-transform: uppercase;
			align-items: center;
			color: $text-gray;
			flex-wrap: wrap;

			& > div {
				text-align: center;
				height: 80px;
				display: flex;
				align-items: center;
				font-weight: 700;
				margin: 0;
				padding: 0;

				&.state-data__state-name {
					display: flex;
					align-items: center;
					width: 100%;
					border-bottom: 1px solid $med-gray;
					border-top: none;

				    background: url(/assets/icons/med-low.png);
				    background-position: -499px 0px;
				    background-repeat: no-repeat;
				    background-size: 600px;

				    @media #{$up-to-tablet} {
				    	background-position: -525px 0px;
				    }

				    &.low-low { background-image: url(/assets/icons/low-low.png); }
				    &.low-med { background-image: url(/assets/icons/low-med.png); }
				    &.low-high { background-image: url(/assets/icons/low-high.png); }

				    &.med-low { background-image: url(/assets/icons/med-low.png); }
				    &.med-med { background-image: url(/assets/icons/med-med.png); }
				    &.med-high { background-image: url(/assets/icons/med-high.png); }
				    
				    &.high-low { background-image: url(/assets/icons/high-low.png); }
				    &.high-med { background-image: url(/assets/icons/high-med.png); }
				    &.high-high { background-image: url(/assets/icons/high-high.png); }

					@media #{$tablet-landscape-up} {
						width: 40%;
						border-bottom: none;
					}

					.header-holder {
						width: 100%;
						display: flex;
						justify-content: left;
						align-items: center;

						@media #{$tablet-up} {
						    margin-left: 1rem;
						}

						.state-name {
							font-size: 24px;

							@media #{$phablet-up} {
								font-size: 30px;
							}
						}

						svg {
							height: 55px;
						}
					}

					img {
						max-height: 60px;
					    margin-right: 2.5rem;

					    @media #{$up-to-tablet} {
					    	margin-right: 1.75rem;
					    }
					}
				}
				& > span, & > a {
					margin: 0 auto;
					font-size: 18px;
				}
				&.state-data__dropdown {
					position: relative;
					display: flex;

					&.open {
						.state-dropdown {
							display: block;
						}

						.arrow {
						   transform: rotate(90deg);
						}
					}

					@media #{$tablet-landscape-up} {
						border-left: 1px solid $med-gray;
					}
					span {
						@media #{$up-to-phablet} {
							padding: 0 1rem;
							line-height: 1.15;
						}
					}
				}
				&.state-data__back {
					border: none;

					a {
						color: $text-gray;
						text-decoration: underline;

						@media #{$up-to-phablet} {
							padding: 0 1rem;
							line-height: 1.15;
						}
					}
				}
				&.state-data__dropdown, &.state-data__back {
					width: 50%;

					@media #{$tablet-landscape-up} {
						width: 30%;
					}
				}
			}
		}
	}

	section {
		background-color: $lightest-gray;

		.header {
			color: $white;
			min-height: 105px;
			font-family: $font-headings;
			font-weight: 800;
			padding: 1rem;

			@media #{$phablet-up} {
				padding: 1rem 2rem;
				min-height: 125px;
			}

			.state-data__score {
				font-size: 45px;
				line-height: 1.2;
				margin: 0 1rem 0 0;
				float: left;

				@media #{$phablet-up} {
					font-size: 70px;
					margin: 0 2rem 0 0;
				}
			}
			h3, .state-data__rank {
				margin-top: 5px;
				font-weight: 800;
				font-size: 20px;
				text-transform: uppercase;
				display: inline-block;

				@media #{$phablet-up} {
					font-size: 24px;
				}

				@media #{$tablet-up} {
					font-size: 30px;
				}
			}
			.state-data__scale {
				display: block;
				font-size: 14px;
				font-weight: 600;

				span {
					font-size: 15px;

					&:first-of-type {
						margin-right: 1rem;
					}
					@media #{$phablet-up} {
						display: inline-block;

						&:first-of-type {
							margin-right: 2rem;
						}
					}

				}
				@media #{$phablet-up} {
					margin-top: .5rem;
				}
			}
		}
		.body {
			color: $med-gray;
			position: relative;

			h4 {
				font-family: $font-headings;
				font-weight: 800;
				text-transform: uppercase;
				font-size: 18px;

				&.sideways {
					text-transform: uppercase;
					text-align: center;
					font-size: 20px;
					padding: 1rem 0;
					border-bottom: 1px solid $med-gray;

					@media #{$tablet-up} {
						transform-origin: 0 0;
						transform: rotate(-90deg) translateX(-250px);
						margin: 2rem 2.25rem;
						position: absolute;
						border-bottom: none;
						top: calc(50% - 170px);
    					left: -18px;
					}
				}
				@media #{$phablet-up} {
					font-size: 22px;
				}
			}
			.body-groups {
				.group {
					padding:  2.5rem 1.5rem;

					&:first-of-type {
						border-bottom: 1px solid $med-gray;
					}
					p {
						font-size: 15px;
						margin: 1em 0;
						max-width: 750px;
					}
					.stats {
						display: flex;
						font-weight: 700;
						justify-content: flex-start;
						flex-wrap: wrap;

						.stat {
							display: flex;
							flex-direction: column;
							width: 120px;
							margin: 1em 0 0;

							&:last-of-type {
								/*margin-right: none;*/
							}

							.name {
								text-transform: uppercase;
								font-size: 10px;
								max-width: 85px;
							}
							.number {
								font-size: 26px;
							}
							@media #{$tablet-up} {
								margin-bottom: 0;
							}
						}
					}
					&.social .stats .name {
						height: 30px;
					}
					@media #{$tablet-up} {
						/*padding: 1.5rem;*/
						border-left: 1px solid $med-gray;
					}
				}
				@media #{$tablet-up} {
					width: calc(100% - 100px);
					position: relative;
					left: 100px;
				}
			}
			.definitions {
				display: flex;
				border-top: 1px solid $med-gray;
				cursor: pointer;

				h4 {
					display: inline-block;
					height: 100%;
					padding: 1rem;
				}

				&.visible .arrow {
					transform: rotate(90deg);
				}
			}
			.expandable {
				justify-content: flex-start;
				flex-wrap: wrap;
				border-top: 1px solid $med-gray;
				display: none;

				.expandable-item {
					width: 100%;
					padding: 1rem 1.5rem;
					border-left: 1px solid $med-gray;

					h5 {
						text-transform: uppercase;
						font-weight: 700;
						border-bottom: 1px solid $gray;
						margin-bottom: 1.25rem;
					    padding-bottom: 1.25rem;
						display: flex;
						align-items: baseline;
					}
					p {
						font-size: 15px;
					}
					&:last-of-type {
						border-right: 1px solid $med-gray;
					}
					&:first-of-type {
						border-left: none;
					}
					@media #{$phablet-up} {
						padding: 2rem 3rem;
					}
				}
				@media #{$phone-only} {
					.expandable-item {
						border-left: none;
						border-right: none;

						h5 {
							height: auto;
							padding-bottom: .5rem;
						}
					}
				}
				@media #{$phablet-only} {
					.expandable-item {
						width: 50%;

						&:nth-of-type(2n+1) {
							border-left: none;
						}
						&:nth-of-type(2n) {
							border-right: none;
						}
					}
				}
				@media #{$tablet-only} {
					.expandable-item {
						width: 33.33%;

						&:nth-of-type(3n+1) {
							border-left: none;
						}
						&:nth-of-type(3n) {
							border-right: none;
						}
					}
				}
				@media #{$laptop-only} {
					.expandable-item {

						width: 25%;

						&:nth-of-type(4n+1) {
							border-left: none;
						}
						&:nth-of-type(4n) {
							border-right: none;
						}
					}
				}
				@media #{$desktop-up} {
					.expandable-item {
						width: 20%;

						&:nth-of-type(5n+1) {
							border-left: none;
						}
						&:nth-of-type(5n) {
							border-right: none;
						}
					}
				}
				&.visible {
					display: flex;
				}
			}

		}

		&#vulnerability {
			.header {
				&.low {background-color: $v-low;}
				&.med {background-color: $v-med;}
				&.high {background-color: $v-high;}
			}
		}
		&#preparedness {
			.header {
				&.low {background-color: $p-low;}
				&.med {background-color: $p-med;}
				&.high {background-color: $p-high;}
			}

			.body .body-groups {
				.stats {
					padding-top: 1em;

					&:first-of-type {
						padding-bottom: 1.75em;
						padding-top: 0;
					}

					.stat {
						width: 50%;

						.name {
							max-width: 120px;	
						}
						@media #{$phablet-up} {
							width: 150px;
						}
					}
				}
				.related .stats .stat {
					width: 50%;

					.name {
						max-width: 150px;
						min-height: auto;
						margin-bottom: 1rem;
					}

					@media #{$phablet-up} {
						width: 180px;
					}
				} 
			}
		}
	}
}

.state-data__preparedness--text,
.state-data__vulnerability--text {
	margin-left: 100px;	

	@media #{$tablet-up} {
		margin-left: 136px;	
	}

	h3 {
		margin-bottom: 0;
	}
}

.state-dropdown {
	display: none;
	position: absolute;
    top: 80px;
    right: 0;
    z-index: 1;

	ul {
		max-height: 300px;
		background-color: #d8d8d8;
		border: 1px solid $med-gray;
		overflow: scroll;
		padding: 1rem 1.25rem;
	}
	li { 
		list-style-type: none;
		text-align: left;
	}

	a {
		color: $med-gray;
		font-size: 14px;
	}
}

.symbols .number,
.symbols .isTrue {
	background-size: contain;
	background-image: url(/assets/icons/x-off.svg);
	background-repeat: no-repeat;
	height: 40px;
	width: 40px;
	display: block;
}

.symbols .isTrue { 
	background-image: url(/assets/icons/check.svg);
}

.state-data__container {
	@media #{$up-to-tablet} {
		width: 100% !important;
	}

	@media #{$tablet-up} {
		margin-top: -7vw !important;
	    margin-bottom: 3rem !important;
	}
}

.mobile-details {
	display: flex;

	@media #{$tablet-up} {
		display: none !important; 
	}

	&__rank {
		text-align: center;
		font-size: 11px;
	    text-transform: uppercase;
		flex-basis: 50%;
		padding: .25rem 0 .75rem;
		font-family: $font-copy;
		line-height: 1;

		&.v-low { background-color: $v-low }
		&.v-med { background-color: $v-med }
		&.v-high { background-color: $v-high }

		&.p-low { background-color: $p-low }
		&.p-med { background-color: $p-med }
		&.p-high { background-color: $p-high }

		&:first-of-type {
			border-right: 1px solid $white;
		}

		span {
			font-weight: bold;
			font-size: 14px;
			display: block;
		}
	}
}

.dropdown-container {
	border-bottom: 1px solid $white;
	position: relative;

	@media #{$tablet-up} {
		display: none;
	}

	.state-dropdown__homepage {
		display: block;
		position: relative;
		top: 0;
	}

	.state-placeholder {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 12px;
		display: block;
		padding-bottom: .75rem;
		cursor: pointer;

		&:after {
			content: "";
			background-image: url(/assets/icons/arrow.svg);
			background-repeat: no-repeat;
			background-size: contain;
			width: 12px;
			height: 12px;
			display: inline-block;
			position: relative;
			transform: rotate(90deg);
			margin-left: 3px;
			vertical-align: bottom;
		}
	}

	&.open {
		ul {
			display: block;
		}
	}

	ul {
		background-color: #FFFFFF;
		position: absolute;
		width: 100%;
		top: 28px; 
		padding: 0;
		max-height: 375px;
		display: none;

		li {	
			color: #626E75;
			font-size: 14px;
			text-align: center;
			display: block;
			text-transform: uppercase;
			padding: 1rem 0 .75rem;
			border-bottom: 1px solid #626E75;
			font-weight: bold;
			cursor: pointer;
		}
	}
}

.slick-arrow {
	position: absolute;
    left: 5%;
    top: calc(100% + 2rem);

    &:before {
    	content: "";
    	background-image: url(/assets/icons/arrow-straight.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 50px;
		height: 30px;
		display: inline-block;
		position: relative;
    }

    &.slick-next {
    	right: 5%;
    	left: initial;
		transform: rotate(180deg);
    }
}

.state-page__btn-wrapper {
	opacity: 1;
	transition: .25s all ease-in-out;

	&.inactive {
		opacity: .5;

		a {
			cursor: default;

			&:hover {
				text-decoration: none;
				cursor: default;
			}
		}
	}	
} 

[data-vcolor="low"]  { background-color: $v-low; }
[data-vcolor="med"]  { background-color: $v-med; }
[data-vcolor="high"]  { background-color: $v-high; }

[data-pcolor="low"]  { background-color: $p-low; }
[data-pcolor="med"]  { background-color: $p-med; }
[data-pcolor="high"]  { background-color: $p-high; }


.alaska {
	.header-holder img {
		@media #{$tablet-up} {
			max-height: 120px !important;
		    margin-left: -2.5rem;
		    margin-right: 1.5rem;
		}
	}
}

.delaware {
	.header-holder {
		margin-left: .5rem;

		@media #{$tablet-up} {
	    	margin-left: 1.5rem !important;
	    }
	}
}

.hawaii {
	.header-holder img {
		@media #{$tablet-up} {
			max-height: 80px !important;
		    margin-left: -.5rem;
		}
	}
}
