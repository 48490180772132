a,
:link,
:visited {
	text-decoration: none;
}

a {
	&:hover {
		text-decoration: underline;
	}
}

p a,
p :link,
p :visited  {	
	text-decoration: underline;

	&:hover,
	&.btn {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
}

.divider {
	width: 100%;
	margin: 2rem 0;
	height: 20px;
	background-image: url(/assets/icons/hash-marks.svg);
	background-size: cover;

	@media #{$tablet-up} {
		margin: 4rem 0;
	}

	@media #{$laptop-up} {
		margin: 6rem 0;
	}

	@media #{$desktop-up} {
		margin: 8rem 0;
	}

	&-basic {
		border-bottom: 1px solid #626e75;
		width: calc(100% + 3rem);
	    margin-left: -1.5rem;
	    margin-top: -1rem;
	    margin-bottom: 1.5rem;
	}
}

*:focus {outline:none}

@import "sliders";
@import "hamburger";