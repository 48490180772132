.attribution {
	background-image: linear-gradient(#bfa479, #a7a284, #a7a284);
	color: $white;
	padding: 0 0 2rem;


	.container {
		padding-top: 2rem;

		@media #{$tablet-up} {
			padding-top: 3rem;
		}
	}

	.team {
		display: flex;
		flex-wrap: wrap;

		@media #{$tablet-up} {
			margin-top: 4rem;
		}

		@media #{$laptop-up} {
			width: 80%;
		}

		&__wrapper {	
			border-top: 1px solid $white;
			padding: 2.5rem 0;
			margin-top: 1.5rem;
		}

		&-member {
			border-right: 1px solid $white;
			border-bottom: 1px solid $white;
			flex-basis: 33%;
			flex-grow: 1;
			padding: 1.5rem;

			@media #{$up-to-tablet} {
				flex-basis: 100%;
				border-right: none;
				border-bottom: 1px solid $white !important;
				padding-left: 0 !important;
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}

			.name {
				display: block;
				font-weight: bold;
				margin-bottom: 1rem;
			}

			&:nth-of-type(3),
			&:nth-of-type(6) {
				padding-right: 0;
				border-right: none;
			}

			&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3) {
				padding-top: 0;
			}

			&:nth-of-type(1),
			&:nth-of-type(4) {
				padding-left: 0;
			}

			&:nth-of-type(4),
			&:nth-of-type(5),
			&:nth-of-type(6) {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}

	&__intro {
		@media #{$laptop-up} {
			width: 80%;
		}
	}

	&__logos {
		margin: 2rem 0;

		@media #{$phablet-up} {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		@media #{$laptop-up} {
			width: 80%
		}

		.logo {
			padding-right: 12%;
			height: auto;
			flex-basis: 33%;
			flex-grow: 1;

			@media #{$up-to-phablet} {
				margin: 2rem 0;
				max-width: 80%;
			}

			img {
				max-width: 100%;
			}

			&:first-of-type {
				max-width: 500px;
			}

			&:last-of-type {
				flex-basis: 8%;
				max-width: 150px;
				padding-right: 0;
			}

			&:nth-of-type(2) {
				max-width: 450px;
			}
		}
	}
}