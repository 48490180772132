.slider {
	display: flex;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		height: 1px;
		width: calc(100% - 24px);
		background-color: $white;
		top: 10px;
		left: 0;

		@media #{$laptop-up} {
			width: calc(90%);
		}
	}

	&-vulnerability:after {
		left: 24px;

		@media #{$laptop-up} {
			left: 10%;
		}
	}

	span {
		height: 20px;
		flex-grow: 1;
		position: relative;
		width: 30%;

		&.low:before,
		&.med:before,
		&.high:before {
			content: "";
			position: absolute;
			height: 28px;
			left: calc(50% + 2px);
			width: 1px;
			background-color: $white;
			top: 0;
		}

		&.neutral {
			border: 1px solid $white;
			width: 25px;
			background-image: url(/assets/icons/hash-inactive.svg);
			background-size: 425px 50px;
		    background-repeat: no-repeat;
		    background-position: center;

			@media #{$laptop-up} {
				width: 10%;
			}

			.text {
				width: 80px;
				text-indent: -55px;
			}
		}

		.text {
			bottom: -2rem;
			color: $white;
			font-size: 11px;
			left: 0;
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			width: 100%;
		}
	}

	&-vulnerability {
		span {
			&.low { background-color: $v-low }
			&.med { background-color: $v-med }
			&.high { background-color: $v-high }
		}
	}

	&-preparedness {
		span {
			&.low { background-color: $p-low }
			&.med { background-color: $p-med }
			&.high { background-color: $p-high }
		}
	}

	&-wrapper {
		flex-basis: 40%;

		@media #{$up-to-tablet} {
			margin-bottom: 2.5rem;
		}
	}

	&s {
		position: relative;

		@media #{$up-to-tablet} {
			display: none;
		}

    	@media #{$tablet-up} {
    		display: flex;
			justify-content: space-between;
    		align-items: center;
    	}
	}
}


#reset {
	@media #{$up-to-tablet} {
		display: none;
	}
}


.ui-corner-all, 
.ui-corner-bottom, 
.ui-corner-right, 
.ui-corner-br {
    height: 21px !important;
    background: none !important;
    border: none !important;
}

.ui-slider-handle {
	background-color: $white !important;
	border-radius: 50% !important;
	height: 27px !important;
	width: 27px !important;
}