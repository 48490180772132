.map {
	background-image: linear-gradient(#707e85, #b1aeab);
	color: $white;
	text-align: center;
	padding: 0 0 2rem;

	&__intro-text {
		max-width: 960px;
		margin: auto;
	}

	&-controls {
		padding-top: 2rem;

		@media #{$tablet-up} {
			padding-top: 3rem;
		}
	}

	&-controls__info-box {
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		margin: 3rem 0 2rem;

		@media #{$up-to-tablet} {
			margin: 3rem 0 1rem;
		}

		.container {
			display: flex;
			justify-content: space-between;
		}

		.info-box {
			padding: 1.5rem 3% 1rem;
			position: relative;
			text-transform: uppercase;
			cursor: pointer;
			width: 40%;

			@media #{$up-to-tablet} {
				padding: 1rem 0 .75rem;
				width: 50%;
			}

			&:nth-of-type(2) {
				border-right: 1px solid white;
				border-left: 1px solid white;
				width: 20%;

				&:after {
					background-image: url(icons/icon-how.svg);
					background-position: 1px 1px;
				    background-repeat: no-repeat;
				    border: 1px solid white;
				    border-radius: 50%;
				}

				&.open:after {
					background-image: url(icons/icon-how-dark.svg);
				}

				@media #{$up-to-tablet} {
					display: none;
				}
			}

			&:nth-of-type(3) {
				&:after {
					background-image: url(icons/icon-preparedness.svg);
				}

				&.open:after {
					background-image: url(icons/icon-preparedness-dark.svg);
				}

				@media #{$up-to-tablet} {
					border-left: 1px solid $white;
				}
			}	

			&.open {
				.info-box__details {
					display: block;
				}

				&:after {
					background-image: url(icons/icon-vulnerability-dark.svg);
				}

				&:before {
					background-color: $white;
				}
			}

			&:before,
			&:after {
				content: "";
				background-image: url(icons/icon-vulnerability.svg);
				background-size: contain;
				height: 40px;
				width: 40px;
				position: absolute;
				top: -20px;
				left: calc( 50% - 20px );
				z-index: 100;

				@media #{$up-to-tablet} {
					height: 32px;
	    			width: 32px;
	    		}
			}

			&:before {
				background-image: none;
				background: #7c878b;
				border-radius: 50%;
			}

			&:last-of-type {
				.info-box__details  {
					@media #{$up-to-tablet} {
					    left: -100%;
					}
				}		
			}

			&__details {
				display: none;
				position: absolute;
				width: 100%;
				left: 0;
				top: 0;
				padding: 2rem 8%;
				background-color: #707e85;
				text-align: center;
				z-index: 10;

				@media #{$up-to-tablet} {
					width: 200%;
				}

				p {
					font-size: 11px;
				}
			}

			.title {
				font-size: 14px;
				font-weight: bold;
				text-align: center;

				&:after {
					content: "";
					background-image: url(/assets/icons/arrow.svg);
					background-repeat: no-repeat;
					background-size: contain;
					width: 12px;
					height: 12px;
					display: inline-block;
					position: relative;
					transform: rotate(90deg);
					margin-left: 3px;
					vertical-align: bottom;
				}

				@media #{$up-to-tablet} {
					font-size: 12px;
				}
			}
		}
	}

	&__state-data {
		border: 1px solid $white;
		max-width: 600px;
		text-align: left;
		margin: auto;

		@media #{$up-to-tablet} {
			border-right: none;
			border-left: none;
			max-width: none;
		}

		&>div {
			display: flex;
		}

		.headers,
		.stats {
			border-bottom: 1px solid $white;

			span {
				width: 30%;
				border-right: 1px solid $white;
				padding: .5rem .75rem;

				@media #{$up-to-tablet} {
					display: none;
				}

				@media #{$tablet-up} {
					width: 25%;
				}

				&:nth-of-type(3) {
					width: 40%;
					border-right: none;
					font-size: 14px;
					line-height: 1.2;
					padding-bottom: 2rem;

					@media #{$up-to-tablet} {
						width: 100%;
						display: block;
						text-align: center;
					}

					@media #{$tablet-up} {
						width: 50%;
					}
				}

				&.large {
					font-size: 18px;
					font-weight: bold;
					padding-bottom: .5rem;

					@media #{$up-to-tablet} {
						text-transform: uppercase;
					}

					@media #{$tablet-up} {
						font-size: 23px;
					}
				}

				&.score-headline {
					text-transform: uppercase;
					line-height: 1.1;
					font-size: 10px;

					@media #{$tablet-up} {
						font-size: 13px;
					}
				}

				&.score-num {
					font-size: 48px;
					font-weight: bold;
					line-height: 45px;

					@media #{$tablet-up} {
						font-size: 58px;
					}
				}
			}
		}

		.stats {
			@media #{$up-to-tablet} {
				border-bottom: none;
			}
		}

		.detials {
			&>div {
				display: block;
				width: 30%;
				border-right: 1px solid $white;
				padding: .5rem .75rem;

				@media #{$up-to-tablet} {
					display: none;
				}

				@media #{$tablet-up} {
					width: 25%;
				}

				&:nth-of-type(3) {
					width: 40%;
					border-right: none;
					background-color: $white;

					@media #{$up-to-tablet} {
						display: block;
						width: 100%;
						text-align: center;
					}

					@media #{$tablet-up} {
						width: 50%;
					}

					a {
						color: #ACAAAA;
						font-size: 18px;
						font-weight: bold;

						@media #{$tablet-up} {
							font-size: 20px;
						}
					}
				}

				span {
					display: block;
					font-size: 11px;
				}
			}
		}
	}

	&-button__container {
		display: flex;
		border: 1px solid $white;
		min-width: 355px;

		@media #{$laptop-up} {
			width: 30%;
			min-width: 415px;
		}

		.map-button {
			border-right: 3px solid $white;
			padding-bottom: 1.4rem;
			cursor: pointer;
			background-image: url(/assets/icons/hash-inactive.svg);
			background-size: 550px 23px;
		    background-repeat: no-repeat;
		    background-position: -11px 3rem;

		    @media #{$laptop-up} {
		    	width: 34%;
		    }

		    &.reset span {
		    	line-height: 2.1;
		    }

		    &.reset:hover,
		    &.reset.active {
				background-image: url(/assets/icons/hash-active.svg);
		    }

		    &.vuln:hover,
		    &.vuln.active {
				background-image: url(/assets/icons/v-hash-active.svg);
		    }

		    &.prep:hover,
		    &.prep.active {
				background-image: url(/assets/icons/p-hash-active.svg);
		    }

			&.last {
				border-right: none;
			}
			
			.text {
				font-size: 11px !important;
				display: inline-block;
				line-height: 1;
				padding: .75rem .2rem;
				border-bottom: 1px solid $white;
				min-height: 49px;
    			width: 100%;
    			text-transform: uppercase;
			}
		}
	}
}

.svg-map {
	margin-top: 3rem;
	cursor: pointer;
	display: none;

	@media #{$tablet-up} {
		display: block;
	}

	svg {
		width: 100%;
	}
}

.slider {
	.slider-slide {
    	position: relative;
    	width: 200px;
    }

    a {
	    position: absolute;
	    cursor: pointer;
	}
}

.mobile-states {
	padding: 1rem 0 3rem;
	@media #{$tablet-up} {
		display: none;
	}
}

.svg-wrapper {
	@media #{$tablet-up} {
		display: flex;
		align-items: flex-start;
	}
}

.main-svg {
	@media #{$tablet-up} {
		flex-basis: 75%;
	    margin: 0 5%;
	}
}

.misc-states {
	border: 1px solid $white;
	flex-basis: 13%;
    margin-top: 8rem;

	.misc-state {
		border-bottom: 1px solid $white;

		&:last-of-type {
			border-bottom: none;
		}
	}
}

.state-block__name {
	text-transform: uppercase;
	display: block;
	padding-bottom: 1.5rem;
	font-size: 11px;
}

.disclaimer-box {
	padding: 3vw 1rem;

	@media #{$up-to-tablet} {
		display: none;
	}

	.disclaimer-icon {
		border: 1px solid $white;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		margin: 1rem auto;
		display: block;
	}
}
